<template>
  <div class="py-sm-10 py5">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-card class="v-card--no-border" color="transparent">
          <h1 class="font-weight-bold mb-5 text-center">
            {{ $t('adminCompanyDocumentHeading') }}
          </h1>
          <p
            v-if="activeRole !== 'ROLE_KEY_ACCOUNT'"
            class="text-center mx-auto comany-documents-lead"
            v-html="$t('messageContractualDocuments')"
          ></p>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <company-documents-data-table></company-documents-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {} from '@mdi/js';
import { ref, getCurrentInstance } from '@vue/composition-api';

import CompanyDocumentsDataTable from '@/components/table/CompanyDocumentsDataTable.vue';

export default {
  name: 'AdminCompanyDocuments',
  components: {
    CompanyDocumentsDataTable,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const toggleExclusive = ref(undefined);

    const { activeRole } = vm.$store.getters;

    return {
      toggleExclusive,
      activeRole,
    };
  },
};
</script>

<style scoped>
.comany-documents-lead {
  max-width: 700px;
}
</style>
